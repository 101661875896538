import store from "@/store/store";
import ENUM from "@/store/enums";

/**
 * Checks if auth could be initialized, waiting up to 12.5 seconds for it to finish.
 * @returns {Promise<Boolean>} true if auth could be initialized, false otherwise.
 */
export function waitForAuth() {
  return new Promise(((resolve) => {
    if (store.state.auth.status === ENUM.LOADED) {
      return resolve(true)
    }

    let timer;
    let calls = 0;

    timer = setInterval(() => {
      if (store.state.auth.status === ENUM.LOADED) {
        clearInterval(timer)
        resolve(true)
      } else if (calls < 5) {
        // In the number of calls are the time that we would wait for the store to
        // be ready before redirecting
        // console.warn(calls);
        calls++
      } else {
        clearInterval(timer)
        resolve(false)
      }
    }, 250)
  }))
}


