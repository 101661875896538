import Vue from 'vue'
import Vuex from 'vuex'
import appointments from './appointments'
import auth from './auth'
import candidates from './candidates'
import company from './company'
import statistics from "./statistics";
import vacancies from './vacancies'
import zones from './zones'
import users from './users'

Vue.use(Vuex)

export default new Vuex.Store({
    namedspaced: true,
    modules:{
        appointments: appointments,
        auth: auth,
        candidates: candidates,
        company: company,
        statistics: statistics,
        vacancies: vacancies,
        zones: zones,
        users: users,
    }
});