import Vuex from "vuex";
import Vue from "vue";
import { getFunctions, httpsCallable } from "firebase/functions";

import ENUM from "@/store/enums";

Vue.use(Vuex);

const state = {
  status: ENUM.INIT,
  selectedZone: "",
  vacanciesInZone: [],
  vacancyData: {},
  createVacancyAttempt: "",
  archive: true,
  saveDay: "",
  alertOk: false,
  alertOkDel: false,
  alertOkEd: false,
  alertUnarchived: false,
  editVacancy: false,
  editSchedule: false,
};

const getters = {
  getAllVacancies: (state) => {
    const vacanciesToPrint = [];
    if (typeof state.vacanciesInZone === "string") {
      state.vacanciesInZone = [];
    }
    const isArchive = state.archive;
    state.vacanciesInZone.forEach(function (vacancy) {
      if (
        vacancy.vacancy_is_active &&
        (isArchive ? !vacancy.vacancy_is_archived : vacancy.vacancy_is_archived)
      ) {
        const vacancyInfoToPrint = {
          id: vacancy.vacancy_id,
          name: vacancy.vacancy_name,
          availableVacancies: vacancy.number_of_available_vacancies,
          startDate: vacancy.vacancy_created_at,
          endDate: vacancy.vacancy_expires_at,
          workHours: vacancy.vacancy_job_hours,
          createdBy: vacancy.vacancy_created_by_fullname,
          status: vacancy.vacancy_status,
        };
        vacanciesToPrint.push(vacancyInfoToPrint);
      }
    });
    // console.log(vacanciesToPrint)
    return vacanciesToPrint;
  },
  getHouranDay: (state) => state.saveDay,
  getVacancyData: (state) => state.vacancyData,
  getCreateVacancyAttempt: (state) => state.createVacancyAttempt,
  getArchive: (state) => state.archive,
};

const mutations = {
  SET_VACANCIES(state, vacancies) {
    state.vacanciesInZone = vacancies;
    state.status = ENUM.LOADED;
  },
  SELECT_ZONE(state, zoneId) {
    state.selectedZone = zoneId;
    state.status = ENUM.LOADED;
  },
  SET_VACANCY_DATA(state, vacancyData) {
    state.vacancyData = vacancyData;
    state.status = ENUM.LOADED;
  },
  VACANCIES_LOADING(state) {
    state.status = ENUM.LOADING;
  },
  SET_RESPONSE_FOR_NEW_VACANCY(state, createVacancyResponse) {
    //console.log(createVacancyResponse, "estoy en la mutación")
    state.createVacancyAttempt = createVacancyResponse;
    state.status = ENUM.LOADED;
  },
  SET_ARCHIVE_VACANCIES(state) {
    state.archive = false;
  },
  SET_ACTIVE_VACANCIES(state) {
    state.archive = true;
  },
  SAVE_DAY(state, saveDay) {
    state.saveDay = saveDay;
  },
  ALERT_OK(state, alertOkk) {
    state.alertOk = alertOkk;
  },
  ALERT_OK_DEL(state, alertOkDell) {
    state.alertOkDel = alertOkDell;
  },
  ALERT_OK_ED(state, alertOkEdd) {
    state.alertOkEd = alertOkEdd;
  },
  ALERT_OK_DESA(state, alertUnarchivedd) {
    state.alertUnarchived = alertUnarchivedd;
  },
  ALERT_EDIT_VACANCY(state, editVacancyY) {
    state.editVacancy = editVacancyY;
  },
  EDITED_SCHEDULE_ALERT(state, editScheduleD){
    state.editSchedule = editScheduleD
  }
};

const actions = {
  requestAllVacancies({ commit }, zoneId) {
    return new Promise((resolve) => {
      const functions = getFunctions();
      // This connectFunctionsEmulator has to be commented in production mode,
      // we have to call it everywhere we have functions to call
      //connectFunctionsEmulator(functions, "localhost", 5001);
      const getAllActiveVacancies = httpsCallable(
        functions,
        "readAllActiveVacancies"
      );
      const userCompanyId = this.state.auth.user.user_company;
      getAllActiveVacancies({
        company_id: userCompanyId,
        company_zone_id: zoneId,
      }).then((result) => {
        const vacancies = result.data;
        commit("SET_VACANCIES", vacancies);
        resolve(result);
      });
      commit("SELECT_ZONE", zoneId);
    });
  },
  async requestVacancyById({ commit }, vacancyId) {
    commit("VACANCIES_LOADING");
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    //connectFunctionsEmulator(functions, "localhost", 5001);
    const getVacancyDetails = httpsCallable(functions, "readVacancyById");
    const userCompanyId = this.state.auth.user.user_company;
    const vacancyZoneId = this.state.vacancies.selectedZone;
    return getVacancyDetails({
      company_id: userCompanyId,
      company_zone_id: vacancyZoneId,
      vacancy_id: vacancyId,
    }).then((result) => {
      const vacancyDetails = result.data;
      commit("SET_VACANCY_DATA", vacancyDetails);
    });
  },
  async deleteVacanciesById({ commit }, vacanciesToDelete) {
    commit("VACANCIES_LOADING");
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    //connectFunctionsEmulator(functions, "localhost", 5001);
    const deactivateVacancies = httpsCallable(
      functions,
      "deleteGroupVacancies"
    );
    await deactivateVacancies({ 
      company_id: await this.state.auth.user.user_company,
      company_zone_id: await this.state.vacancies.selectedZone,
      vacancies: vacanciesToDelete 
    }).then((result) => {
        const updatedVacancies = result.data;
        commit("SET_RESPONSE_FOR_NEW_VACANCY", updatedVacancies);
        commit("ALERT_OK_DEL", true);
      });
    commit("ALERT_OK_DEL", true);
  },
  async archiveVacanciesById({ commit }, vacanciesToArchive) {
    commit("VACANCIES_LOADING");
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    //connectFunctionsEmulator(functions, "localhost", 5001);
    const ArchivedVacancies = httpsCallable(functions, "archivedVacancyById");
    const userCompanyId = this.state.auth.user.user_company;
    const vacancyZoneId = this.state.vacancies.selectedZone;
    ArchivedVacancies({
      company_id: userCompanyId,
      company_zone_id: vacancyZoneId,
      vacancies_ids: vacanciesToArchive,
    }).then((result) => {
      const updatedVacancies = result.data;
      commit("SET_RESPONSE_FOR_NEW_VACANCY", updatedVacancies);
      commit("ALERT_OK_ED", true);
    });
    commit("ALERT_OK_ED", true);
  },
  async unarchiveVacanciesById({ commit }, vacanciesToArchive) {
    commit("VACANCIES_LOADING");
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const UnarchivedVacancies = httpsCallable(
      functions,
      "dearchivedVacancyById"
    );
    const userCompanyId = this.state.auth.user.user_company;
    const vacancyZoneId = this.state.vacancies.selectedZone;
    return UnarchivedVacancies({
      company_id: userCompanyId,
      company_zone_id: vacancyZoneId,
      vacancies_ids: vacanciesToArchive,
    }).then((result) => {
      const updatedVacancies = result.data;
      commit("SET_RESPONSE_FOR_NEW_VACANCY", updatedVacancies);
      commit("ALERT_OK_DESA", true);
    });
  },
  async updateVacancyById({ commit }, payload) {
    commit("VACANCIES_LOADING");
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    //connectFunctionsEmulator(functions, "localhost", 5001);
    const updateVacancy = httpsCallable(functions, "updateVacancyById");
    const companyId = this.state.auth.user.user_company;
    const companyZoneId = this.state.vacancies.selectedZone;
    const createdById = this.state.vacancies.vacancyData.vacancy_created_by;
    const vacancyStatus = this.state.vacancies.vacancyData.vacancy_status;
    const vacancyArchived =
      this.state.vacancies.vacancyData.vacancy_is_archived;
    payload["company_id"] = companyId;
    payload["company_zone_id"] = companyZoneId;
    payload["vacancy_created_by"] = createdById;
    payload["vacancy_status"] = vacancyStatus;
    payload["vacancy_is_archived"] = vacancyArchived;
    updateVacancy(payload).then((result) => {
      const vacancyDetails = result.data;
      commit("SET_VACANCY_DATA", vacancyDetails);
      commit("ALERT_EDIT_VACANCY", true);
    });
  },
  async updateVacancyScheduleById({ commit }, payload) {
    commit("VACANCIES_LOADING");
    const functions = getFunctions();
    const getVacancyUpdate = httpsCallable(
      functions,
      "updateVacancyScheduleById"
    );
    await getVacancyUpdate(payload).then((result) => {   
      const resultData = result.data;
      this.dispatch("requestVacancyById", payload.vacancy_id)
      commit("SET_VACANCY_DATA", resultData);
      commit("EDITED_SCHEDULE_ALERT", true)
    });
  },
  
  async createVacanciesById({ commit }, { newVacancy }) {
    commit("VACANCIES_LOADING");
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    //connectFunctionsEmulator(functions, "localhost", 5001);
    const getNewVacancy = httpsCallable(functions, "createVacancyById");
    const userCompanyId = this.state.auth.user.user_company;
    const zoneId = newVacancy.company_zone_id;
    const createdById = this.state.auth.user.user_uid;

    newVacancy["company_id"] = userCompanyId;
    newVacancy["company_zone_id"] = zoneId;
    newVacancy["vacancy_created_by"] = createdById;
    newVacancy["vacancy_is_active"] = true;
    newVacancy["vacancy_is_archived"] = false;
    newVacancy["vacancy_status"] = "activa";

    console.warn(
      "This is the newVacancy object JSON: " + JSON.stringify(newVacancy)
    );
    return getNewVacancy(newVacancy).then((result) => {
      // console.log(newVacancy, "newVacancy");
      console.log(result)
      const response = 200;
      console.log(response, "variable")
      commit("SET_RESPONSE_FOR_NEW_VACANCY", response);
      commit("ALERT_OK", true);
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
