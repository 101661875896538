<template>
  <v-app>
    <v-main>
      <!-- Here we have to add the condition to do not show anything in case the user -->
      <!-- is not logged, or think about the possibilities around it -->
      <div v-if="currentView !== 'Login' && currentView !== 'NotFound' && userStateLoaded === true">
        <Nav/>
        <Sidebar/>
      </div>
      <transition v-if="userStateLoaded === true || currentView === 'Login'" name="page" mode="out-in">
        <router-view />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import Nav from "./components/App/Nav.vue";
import Sidebar from "./components/App/Sidebar.vue"

import ENUM from "@/store/enums";
import { mapState, mapGetters } from "vuex";

export default {
  components: { 
    Nav,
    Sidebar 
    },
  data: () => ({
    navLinks: [
      { title: "Mi Perfil", icon: "mdi-account-outline" },
      { title: "Tutoriales", icon: "mdi-school-outline" },
    ],
  }),
  computed: {
    ...mapGetters(["userType"]),
    ...mapState({
      userState: state => state.auth.status,
      userType: state => state.auth.user.user_type,
    }),
    userStateLoaded() {
      return this.userState === ENUM.LOADED;
    },
    currentView() {
      return this.$route.name 
    },
  },
  created() {
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}
a {
  font-weight: bold;
  color: #2c3e50;
}
a router-link-exact-active {
  color: #42b983;
}
.page-enter,
.page-leave-to {
  opacity: 0;
  /* transform: translateX(10px); */
}
.page-enter-active,
.page-leave-active {
  transition: opacity 0.5s ease;
}
</style>