<template>
  <div>
    <v-app-bar app color="white" dark elevation="0">
      <div class="d-flex align-center ml-15">
        <v-img
          alt="Reclutachat Name"
          class="shrink mt-1 hidden-sm-and-down ml-5"
          contain
          min-width="100"
          src="../../assets/logo/reclutachat-letters.png"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>
      <div class="d-flex align-center mr-10">
        <div class="d-flex align-center">
          <p class="name pa-0 ma-0 text-caption" style="color: #000">¡Hola {{userName}}!</p>
        </div>
        <v-menu bottom left offset-y transition="scroll-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-layout align-center>
              <v-icon
                small
                color="#6F50FF"
                v-on="on"
                v-bind="attrs"
                :class="$vuetify.breakpoint.mdAndUp ? 'ml-6' : 'ml-1'"
                >mdi-chevron-down
              </v-icon>
            </v-layout>
          </template>
          <v-list class="vmenucont">
            <v-list-item
              class="vmenu d-flex align-start flex-column justify-center mt-5"
            >
              <v-btn
                color="white"
                class="d-flex justify-center ma-0"
                v-for="link in navLinks"
                :key="link.title"
                :to="link.path"
                text
              >
                <v-icon class="mr-4" small color="#6F50FF">{{
                  link.icon
                }}</v-icon>
                <p
                  class="text-caption pa-0 ma-0 text-capitalize font-weight-light"
                  style="color: #000"
                >
                  {{ link.title }}
                </p>
              </v-btn>
              <v-btn text color="white" class="d-flex justify-start ma-0" @click="logout">
                <v-icon class="mr-4" small color="#6F50FF">mdi-logout</v-icon>
                <p
                  class="text-caption pa-0 ma-0 text-capitalize font-weight-light"
                  style="color: #000"
                >
                  Cerrar sesión
                </p>
              </v-btn>
              <v-btn text color="white" class="d-flex justify-start ma-0" to="/tutorial">
                <v-icon class="mr-4" small color="#6F50FF">mdi-eye-check-outline</v-icon>
                <p
                  class="text-caption pa-0 ma-0 text-capitalize font-weight-light"
                  style="color: #000"
                >
                  Tutorial
                </p>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Nav",
  data: () => ({
    active: false,
    navLinks: [
      { title: "Mi Perfil", icon: "mdi-account-outline" },
      // { title: "Tutoriales", icon: "mdi-school-outline" },
      //  { title: "Ayuda y soporte técnico", icon: "mdi-help" },
    ],
  }),
  computed:{
      ...mapState({
      userName: (state) => state.auth.user.user_first_name,
    }),
  },
  methods: {
    ...mapActions(["logout"]),
  }
};
</script>
