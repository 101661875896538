import {
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
} from "firebase/auth";
//const admin = require("firebase-admin");
import router from "../../router";
import ENUM from "@/store/enums";
import { getFunctions, httpsCallable } from 'firebase/functions';


const state = {
  user: null,
  status: ENUM.INIT,
  companyZones: [],
  currentUser: null,
  userCreated: false,
};

const getters = {
  isAuthenticated: (state) => !!state.user,
  userType: (state) => state.user.user_type,
  getZones: (state) => state.companyZones
};

const mutations = {
  SET_USER(state, user) {
    state.user = user;
    state.status = ENUM.LOADED;
  },
  AUTH_STATUS_LOADING(state) {
    state.status = ENUM.LOADING;
  },
  AUTH_STATUS_LOADED(state) {
    state.status = ENUM.LOADED;
  },
  AUTH_STATUS_ERROR(state) {
    state.status = ENUM.ERROR;
  },
  CLEAR_USER(state) {
    state.user = null;
    state.status = ENUM.INIT;
  },
  SET_ZONES(state, companyZones) {
    state.companyZones = companyZones;
    state.status = ENUM.LOADED;
  },
  SET_NEW_USER(state, user) {
    state.currentUser = user;
    state.status = ENUM.LOADED;
  },
  CREATED_USER_ALERT(state, userCreated){
    state.userCreated = userCreated
  }
};

const actions = {
  async loginWithEmailAndPassword({ commit }, { email, password }) {
    const auth = getAuth();
    let tempEmail = email.trim();
    setPersistence(auth, browserSessionPersistence);
    const loginAttemptInfomation = await signInWithEmailAndPassword(auth, tempEmail, password);
  },
  async initializeAuth({ commit }, user) {
    if (user) {
      commit("SET_USER", user);
      commit("AUTH_STATUS_LOADED");
      
      if (router.currentRoute.name === "Login") {
        router.push("/dashboard");
      }
    } else {
      commit("AUTH_STATUS_ERROR")
    }
  },
  async logout({ commit }) {
    const auth = getAuth();
    await signOut(auth);
    commit("CLEAR_USER");

    router.push("/");
  },
  async registerUser({ commit }, payload) {

    const auth = getAuth();
    let tempLoginEmail = payload.user_login_email.trim();
    await createUserWithEmailAndPassword(auth, tempLoginEmail, payload.user_password).then(
      (userCredential) => {

        const userAuth = userCredential.user;
        const currentUser = this.state.auth.user;
        // console.log(currentUser, "este es el usuario actual")        
    
        const functions = getFunctions();
        // This connectFunctionsEmulator has to be commented in production mode,
        // we have to call it everywhere we have functions to call
        //connectFunctionsEmulator(functions, 'localhost', 5001);
        const createNewUser = httpsCallable(functions, "createNewUser");
        const companyId = this.state.auth.user.user_company;

        let body = {
            user_area: payload.user_area,
            user_company: companyId,
            user_contact_email: payload.user_login_email,
            user_first_name: payload.user_first_name,
            user_last_name: payload.user_last_name,
            user_loging_attempts: 0,
            user_login_email: payload.user_login_email,
            user_phone_number: payload.user_phone_number,
            user_type: payload.user_type,
            user_uid: userAuth.uid,
            user_zones: payload.user_zones
          }
        createNewUser(body).then((result) => {
          // console.log(result)
          router.push("/users");
          commit("CREATED_USER_ALERT", true)
          commit("SET_USER", currentUser);
        });
      }
    );
  },

  async registerUserWithCompany({ commit }, payload) {
    const auth = getAuth();
    let tempLoginEmail = payload.user_login_email.trim()
    await createUserWithEmailAndPassword(auth, tempLoginEmail, payload.user_password).then(
      (userCredential) => {
        const userAuth = userCredential.user;
        const currentUser = this.state.auth.user;
        const functions = getFunctions();
        // This connectFunctionsEmulator has to be commented in production mode,
        // we have to call it everywhere we have functions to call
        //connectFunctionsEmulator(functions, 'localhost', 5001);
        const createNewUser = httpsCallable(functions, "createNewUser");
        const companyId = this.state.company.companyId;
        payload["user_company"] = companyId;
        payload["user_uid"] = userAuth.uid;
        createNewUser(payload).then((result) => {
          const userId = result.data;
          //router.push("/CompaniesTable");
          commit("SET_USER", currentUser)
          commit("SET_NEW_USER", userId);
        });
      }
    );
  },

  async requestZonesByCompanyId({ commit }) {
    const functions = getFunctions();
    const getAllZones = httpsCallable(functions, "readAllZones");
    const userCompanyId = this.state.auth.user.user_company;
    getAllZones({company_id: userCompanyId}).then((result) => {
        const zones = result.data;
        commit("SET_ZONES", zones);
    });
}
};

export default {
  state,
  getters,
  actions,
  mutations,
};
