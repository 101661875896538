import Vuex from "vuex";
import Vue from "vue";
import {
  getFunctions,
  httpsCallable,
} from "firebase/functions";

import ENUM from "@/store/enums";

Vue.use(Vuex);

const state = {
  status: ENUM.INIT,
  allStats: {
    /** All the data that is commented were on the prototype of Reclutachat 
        but is unused in the version 0 deployed **/
    // topCardsStats: null,
    // candidatesHistoryStats: null,
    // vacanciesPercentageStats: null,
    // recruitmentsAverageStats: null,
    // vacanciesVsRecruitmentsStats: null,
    // recruitmentsVsCandidatesInProcessStats: null,
    // recruitmentsPerLocationsStats: null
  }
};

const getters = {
  getAllStats:                      (state) => state.allStats,
  getTopCardsStats:                 (state) => state.allStats.topCardsStats,
  // getCandidatesHistoryStats:        (state) => state.allStats.CandidatesHistoryStats,
  // getVacanciesPercentageStats:      (state) => state.allStats.vacanciesPercentageStats,
  // getRecruitmentsAverageStats:      (state) => state.allStats.recruitmentsAverageStats,
  // getVacanciesVsRecruitmentsStats:  (state) => state.allStats.vacanciesVsRecruitmentsStats,
  // getRecruitmentsPerLocationsStats: (state) => state.allStats.recruitmentsPerLocationsStats,
  // getRecruitmentsVsCandidatesInProcessStats: (state) => state.allStats.recruitmentsVsCandidatesInProcessStats,
};

const mutations = {
  SET_ALL_STATS(state, statsData) {
    // state.allStats.topCardsStats = statsData.top_cards_stats;
    // state.allStats.candidatesHistoryStats   = statsData.candidates_history_stats;
    // state.allStats.vacanciesPercentageStats = statsData.vacancies_percentage_stats;
    // state.allStats.recruitmentsAverageStats = statsData.recruitments_average_stats;
    // state.allStats.vacanciesVsRecruitmentsStats = statsData.vacancies_vs_recruitments_stats;
    // state.allStats.recruitmentsVsCandidatesInProcessStats = statsData.recruitments_vs_candidates_in_process_stats;
    // state.allStats.recruitmentsPerLocationsStats = statsData.top_cards_stats;
    state.allStats = statsData;
    state.status = ENUM.LOADED;
  },
  SET_CANDIDATES_HISTORY(state, statsData) {
    state.allStats.candidatesHistoryStats = statsData;
    state.status = ENUM.LOADED;
  },
};

const actions = {
  async requestTest({ commit }) {
    const statsData = {
      //DIFERENTES ESTADÍSTICAS (TOP CARDS)
      top_cards_stats: {
        //These values could be number or string
        recruitments_number: "12",
        candidates_number: "13",
        concluded_appointments: "14",
        interviews_last_moth: "15",
        new_appointments_created_today: "16",
      },

      //TOTAL DE CANDIDATOS  VS CANDIDATOS ENTREVISTADOS
      candidates_history_stats: {
        //Each space of array for each month
        total_candidates: [50, 38, 48, 101, 101, 82, 69, 67, 54, 63, 81, 72],
        interviewed_candiates: [
          68, 68, 101, 115, 115, 118, 118, 118, 118, 118, 118, 118,
        ],
      },

      //TOTAL DE CANDIDATOS  VS CANDIDATOS ENTREVISTADOS
      vacancies_percentage_stats: {
        //Each space of array for each month
        expired_vacancies: [
          68, 68, 101, 115, 115, 118, 118, 118, 118, 118, 118, 118,
        ],
        available_vacancies: [50, 38, 48, 101, 101, 82, 69, 67, 54, 63, 81, 72],
      },

      //ENTREVISTAS VS CONTRATACIONES
      recruitments_average_stats: {
        //First value in array has interviews number,
        //second one has recruitments number
        recruitments_average: [30, 8],
      },

      //VACANTES VS CONTRATACIONES
      vacancies_vs_recruitments_stats: {
        //First value in array has vacancies number,
        //second one has recruitments number
        vacancies_vs_recruitments: [30, 20],
      },

      //CONTRATACIONES VS CANDIDATOS EN PROCESO
      recruitments_vs_candidates_in_process_stats: {
        //First value in array has recruitments number,
        //second one has rotations number
        recruitments_vs_candidates_in_process: [10, 30],
      },
    };
    commit("SET_ALL_STATS", statsData);
  },
  async requestsAllStats({ commit }) {
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const getAllStats = httpsCallable(functions, "readCompanyYearAnalytics");
   
    const companyId = this.state.auth.user.user_company;
    return getAllStats({ company_id: companyId }).then((result) => {
      const statsData = result.data;
      console.log(statsData)
      commit("SET_ALL_STATS", statsData);
    });
  },
  async requestCandidatesHistoryStats({ commit }) {
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    //connectFunctionsEmulator(functions, "localhost", 5001);
    const getCandidatesHistoryStats = httpsCallable(functions, "FUNCTION_NAME");
    const companyId = this.state.auth.user.user_company;
    getCandidatesHistoryStats({ company_id: companyId }).then((result) => {
      const statsData = result.data.candidates_history_stats;
      commit("SET_CANDIDATES_HISTORY", statsData);
    });
  },
  // From right here to the last action are only templates 
  async requestCandidatesByAppStats({ commit }) {
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    //connectFunctionsEmulator(functions, "localhost", 5001);
    const getCandidatesByAppStats = httpsCallable(functions, "FUNCTION_NAME");
    const companyId = this.state.auth.user.user_company;
    getCandidatesByAppStats({ company_id: companyId }).then((result) => {
      const statsData = result.data.candidates_history_stats;
      commit("SET_CANDIDATES_HISTORY", statsData);
    });
  },
  async requestCandidatesByBotStats({ commit }) {
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    //connectFunctionsEmulator(functions, "localhost", 5001);
    const getCandidatesByBotStats = httpsCallable(functions, "FUNCTION_NAME");
    const companyId = this.state.auth.user.user_company;
    getCandidatesByBotStats({ company_id: companyId }).then((result) => {
      const statsData = result.data.candidates_history_stats;
      commit("SET_CANDIDATES_HISTORY", statsData);
    });
  },
  async requestChatsStartedStats({ commit }) {
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    //connectFunctionsEmulator(functions, "localhost", 5001);
    const getChatsStartedStats = httpsCallable(functions, "FUNCTION_NAME");
    const companyId = this.state.auth.user.user_company;
    getChatsStartedStats({ company_id: companyId }).then((result) => {
      const statsData = result.data.candidates_history_stats;
      commit("SET_CANDIDATES_HISTORY", statsData);
    });
  },
  async requestChatsCompletedStats({ commit }) {
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    //connectFunctionsEmulator(functions, "localhost", 5001);
    const getChatsCompletedStats = httpsCallable(functions, "FUNCTION_NAME");
    const companyId = this.state.auth.user.user_company;
    getChatsCompletedStats({ company_id: companyId }).then((result) => {
      const statsData = result.data.candidates_history_stats;
      commit("SET_CANDIDATES_HISTORY", statsData);
    });
  },
  async requestChatsIncompleteStats({ commit }) {
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    //connectFunctionsEmulator(functions, "localhost", 5001);
    const getChatsIncompleteStats = httpsCallable(functions, "FUNCTION_NAME");
    const companyId = this.state.auth.user.user_company;
    getChatsIncompleteStats({ company_id: companyId }).then((result) => {
      const statsData = result.data.candidates_history_stats;
      commit("SET_CANDIDATES_HISTORY", statsData);
    });
  },
  async requestUsersCreatedStats({ commit }) {
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    //connectFunctionsEmulator(functions, "localhost", 5001);
    const getUsersCreatedStats = httpsCallable(functions, "FUNCTION_NAME");
    const companyId = this.state.auth.user.user_company;
    getUsersCreatedStats({ company_id: companyId }).then((result) => {
      const statsData = result.data.candidates_history_stats;
      commit("SET_CANDIDATES_HISTORY", statsData);
    });
  },
  async requestVacanciesCreatedStats({ commit }) {
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    //connectFunctionsEmulator(functions, "localhost", 5001);
    const getVacanciesCreatedStats = httpsCallable(functions, "FUNCTION_NAME");
    const companyId = this.state.auth.user.user_company;
    getVacanciesCreatedStats({ company_id: companyId }).then((result) => {
      const statsData = result.data.candidates_history_stats;
      commit("SET_CANDIDATES_HISTORY", statsData);
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
