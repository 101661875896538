import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import * as firebase from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import VueExcelXlsx from "vue-excel-xlsx";
import {
  getAuth,
  onAuthStateChanged
} from "firebase/auth";
import {
  getFunctions,
  httpsCallable,
} from 'firebase/functions';

//import dotenv from "dotenv";
import store from "../src/store/store";

import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';

Vue.use(Donut);
Vue.use(VueExcelXlsx);
//dotenv.config();

Vue.config.productionTip = false;
Vue.use(require('vue-moment'));
new Vue({
  vuetify,
  router,
  store,
  created() {
    const firebaseConfig = {
      apiKey: "AIzaSyAQ37RgnZVo5lFOInK8C9RJkZJCjEOBxZw",
      authDomain: "reclutachatlive.firebaseapp.com",
      projectId: "reclutachatlive",
      storageBucket: "reclutachatlive.appspot.com",
      messagingSenderId: "902908072322",
      appId: "1:902908072322:web:c79ff4b3de4b347c6e3f9b",
      measurementId: "G-LC9TBVT5N0"
    };
    const app = firebase.initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const auth = getAuth();
    // This connectAuthEmulator have to be used only in development
    // In order to be able to connect it, you also have to import the function from firebase/auth
    //connectAuthEmulator(auth, "http://localhost:9099");
    onAuthStateChanged(auth, (user) => {
      if (user) {
        try {
          const functions = getFunctions();
          // This connectFunctionsEmulator has to be commented in production mode, 
          // we have to call it everywhere we have functions to call
          //connectFunctionsEmulator(functions, 'localhost', 5001);

          const getUserByID = httpsCallable(functions, 'readUserById');
          getUserByID({ user_id: user.uid }).then((result) => {
            const userData = result.data[0];
            console.warn("Obtained Reclutachat user data");
            
            this.$store.dispatch("initializeAuth", userData);
          });
        } catch (error) {
          console.error("Error getting auth status", error, "nononono");
        }
      } else {
        console.warn("Without user in Firebase authentication");
        // This line should be uncommmented in production mode because it is the second filter for secure routing
        // this.$store.dispatch("logout");
      }
    });
  },
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
