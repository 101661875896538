import Vuex from "vuex";
import Vue from "vue";
import {
  getFunctions,
  httpsCallable,
} from "firebase/functions";

import ENUM from "@/store/enums";
import router from "../../router";

Vue.use(Vuex);

const state = {
  status: ENUM.INIT,
  zones: [],
  createZone: false,
  editeZone: false,
  deleteZone: false,
  archiveZone: false,
  unarchiveZone: false,
};

const getters = {
  getAllZones: (state) => state.zones,
  getAllZonesToSelect(state) {
    const zonesToSelect = [];
    state.zones.forEach(function (zone) {
      const zoneToPush = {
        value: zone.zone_id,
        text: zone.company_zone_name,
      };
      zonesToSelect.push(zoneToPush);
    });
    return zonesToSelect;
  },
};

const mutations = {
  SET_ZONES(state, zones) {
    state.zones = zones;
    state.status = ENUM.LOADED;
  },
  ZONES_LOADING(state) {
    state.status = ENUM.LOADING;
  },
  CREATED_ZONE(state, createZoneE) {
    state.createZone = createZoneE;
  },
  EDITED_ZONE(state, editeZoneE) {
    state.editeZone = editeZoneE;
  },
  DELETED_ZONE(state, deleteZoneE) {
    state.deleteZone = deleteZoneE;
  },
  ARCHIVED_ZONE(state, archiveZoneE) {
    state.archiveZone = archiveZoneE;
  },
  UNARCHIVED_ZONE(state, unarchiveZoneE) {
    state.unarchiveZone = unarchiveZoneE;
  },
};

const actions = {
  async requestsAllZones({ commit }) {
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    //connectFunctionsEmulator(functions, 'localhost', 5001);
    const getAllActiveZones = httpsCallable(functions, "readZonesByUser");
    const userid = this.state.auth.user.user_uid;
    getAllActiveZones({ user_uid: userid }).then((result) => {
      const zones = result.data;
      commit("SET_ZONES", zones);
    });
  },
  async deleteZones({ commit }, zone_id) {
    commit("ZONES_LOADING");
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    //connectFunctionsEmulator(functions, 'localhost', 5001);
    const deleteZone = httpsCallable(functions, "deactivateZoneById");
    const userCompanyId = this.state.auth.user.user_company;
    const userid = this.state.auth.user.user_uid;
    deleteZone({
      company_id: userCompanyId,
      zone_id: zone_id,
      user_id: userid,
    }).then((result) => {
      const zones = result.data;
      commit("SET_ZONES", zones);
      commit("DELETED_ZONE", false);
    });
  },
  async archiveZones({ commit }, zone_id) {
    commit("ZONES_LOADING");
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    //connectFunctionsEmulator(functions, 'localhost', 5001);
    const archiveZone = httpsCallable(functions, "archiveZoneById");
    const userCompanyId = this.state.auth.user.user_company;
    const userid = this.state.auth.user.user_uid;
    archiveZone({
      company_id: userCompanyId,
      zone_id: zone_id,
      user_id: userid,
    }).then((result) => {
      const zones = result.data;
      commit("SET_ZONES", zones);
      commit("ARCHIVED_ZONE", true);
    });
  },
  async unarchiveZones({ commit }, zone_id) {
    commit("ZONES_LOADING");
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    // connectFunctionsEmulator(functions, 'localhost', 5001);
    const unarchiveZone = httpsCallable(functions, "unarchiveZoneById");
    const userCompanyId = this.state.auth.user.user_company;
    const userid = this.state.auth.user.user_uid;
    unarchiveZone({
      company_id: userCompanyId,
      zone_id: zone_id,
      user_id: userid,
    }).then((result) => {
      const zones = result.data;
      commit("SET_ZONES", zones);
      commit("UNARCHIVED_ZONE", true);
    });
  },
  async createZone({ commit }, payload) {
    commit("ZONES_LOADING");

    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    // connectFunctionsEmulator(functions, 'localhost', 5001);
    const createZone = httpsCallable(functions, "createNewZone");
    const userCompanyId = this.state.auth.user.user_company;
    const userid = this.state.auth.user.user_uid;
    payload["user_id"] = userid;
    payload["company_id"] = userCompanyId;
    payload["zone_is_active"] = true;
    payload["zone_is_archive"] = false;
    createZone(payload).then((result) => {
      const zones = result.data;
      commit("SET_ZONES", zones);
      commit("CREATED_ZONE", true);
    });
  },

  async createZoneReclutachat({ commit }, zoneName, countryName) {
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    //connectFunctionsEmulator(functions, "localhost", 5001);
    const createRZone = httpsCallable(functions, "createNewZone");
    const userCompanyId = this.state.company.companyId;
    const userid = this.state.auth.currentUser;
    const payload = {
      company_zone_country: countryName,
      company_zone_name: zoneName,
    };
    payload["user_id"] = userid;
    payload["company_id"] = userCompanyId;
    payload["zone_is_active"] = true;
    payload["zone_is_archive"] = false;

    createRZone(payload).then((result) => {
      const zones = result.data;
      router.push("/CompaniesTable");
      commit("SET_ZONES", zones);
      commit("CREATED_ZONE", true);
    });
  },

  async updateZone({ commit }, { zone_id, zoneName, countryName }) {
    commit("ZONES_LOADING");
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    // connectFunctionsEmulator(functions, 'localhost', 5001);
    const updateZone = httpsCallable(functions, "updateZoneById");
    const userCompanyId = this.state.auth.user.user_company;
    const userId = this.state.auth.user.user_uid;
    updateZone({
      user_id: userId,
      zone_id: zone_id,
      company_id: userCompanyId,
      company_zone_country: countryName,
      company_zone_name: zoneName,
      zone_is_active: true,
      zone_is_archive: false,
    }).then((result) => {
      const zones = result.data;
      commit("SET_ZONES", zones);
      commit("EDITED_ZONE", true);
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
