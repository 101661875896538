//import router from "../../router";
import { getFunctions, httpsCallable } from 'firebase/functions';
import ENUM from "@/store/enums";

const state = {
  status: ENUM.INIT,
  allAppointments: [],
};

const getters = {
  allAppointments: (state) => state.allAppointments,
};

const mutations = {
  SET_APPOINTMENTS(state, appointmentsFromServer) {
    state.allAppointments = appointmentsFromServer;
    state.status = ENUM.LOADED;
  },
  APPOINTMENTS_LOADING(state) {
    state.status = ENUM.LOADING;
  },
  APPOINTMENTS_LOADED(state) {
    state.status = ENUM.LOADED;
  },
  APPOINTMENTS_ERROR(state) {
    state.status = ENUM.ERROR;
  },
};

const actions = {
  async requestAppointments({ commit }, vacanciesIds) {
    commit("APPOINTMENTS_LOADING")
    const functions = getFunctions();
    let vacancyArray = [];
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    //connectFunctionsEmulator(functions, "localhost", 5001);
    const getAllAppointmentsByVacancy = httpsCallable(functions, "readAppointmentsByZone");
    const userCompanyId = this.state.auth.user.user_company;
    const zoneId =  this.state.vacancies.selectedZone;
    if (!Array.isArray(vacanciesIds)) {
      vacancyArray.push(vacanciesIds);
    } else {
      vacancyArray = vacanciesIds;
    }
    getAllAppointmentsByVacancy({ candidate_company_id: userCompanyId, candidate_zone_id : zoneId, candidate_vacancy_id: vacancyArray}).then((result) => {
      const appointments = result.data;

      commit("SET_APPOINTMENTS", appointments);
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
