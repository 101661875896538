import Vuex from 'vuex';
import Vue from 'vue';
import { getFunctions, httpsCallable } from 'firebase/functions';

import ENUM from "@/store/enums";

Vue.use(Vuex);


const state = {
  status: ENUM.INIT,
  company: [],
  companyId: '',
  companies: [],
  companySelected: []
};
  
const getters = {
  getCompany: (state) => state.company,
  getCompanyId: (state) => state.companyId,
  getCompanies: (state) => state.companies,
  getSelectedCompany: (state) => state.companySelected
};
  
const mutations = {
  SET_COMPANY(state, company) {
    state.company = company;
    state.status = ENUM.LOADED;
  },
  SET_COMPANY_ID(state, company) {
    state.companyId = company;
    state.status = ENUM.LOADED;
  },
  SET_COMPANIES(state, companies) {
    state.companies = companies;
    state.status = ENUM.LOADED;
  },
  SET_SELECTED_COMPANY(state, company) {
    state.companySelected = company;
    state.status = ENUM.LOADED;
  },
  COMPANIES_LOADING(state) {
    state.status = ENUM.LOADING;
  },
  COMPANIES_LOADED(state) {
    state.status = ENUM.LOADED;
  },
};
  
const actions = {
  async requestsAllCompanies({ commit }) {
        const functions = getFunctions();
        // This connectFunctionsEmulator has to be commented in production mode, 
        // we have to call it everywhere we have functions to call
        //connectFunctionsEmulator(functions, 'localhost', 5001);
        const getAllCompanies = httpsCallable(functions, 'readCompanyById');
        const companyid = this.state.auth.user.user_company;
        getAllCompanies({ "company_id": companyid }).then((result) => {
          const company = result.data;
          commit("SET_COMPANY", company);
    });
  },
  async requestsCompanyByName({ commit }, companyName) {
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode, 
    // we have to call it everywhere we have functions to call
    //connectFunctionsEmulator(functions, 'localhost', 5001);
    const getAllCompanies = httpsCallable(functions, 'readCompanyByName');
    getAllCompanies({ "company_name": companyName }).then((result) => {
      const company = result.data;
      commit("SET_SELECTED_COMPANY", company);
    });
  },
  async readAllCompanies({ commit }) {
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode, 
    // we have to call it everywhere we have functions to call
    //connectFunctionsEmulator(functions, 'localhost', 5001);
    const readAllCompanies = httpsCallable(functions, 'readAllCompanies');
    readAllCompanies({}).then((result) => {
      const companies = result.data;
      commit("SET_COMPANIES", companies);
});
},
  async updateCompanyById({commit}, payload) {
    commit("COMPANIES_LOADING");
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    //connectFunctionsEmulator(functions, "localhost", 5001);
    const getAllCandidates = httpsCallable(functions, "updateCompanyById");
    getAllCandidates(payload).then((result) => {
        const company = result.data;
        commit("COMPANIES_LOADED");
    });
  },

  async createCompany( { commit } , payload) {
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    //connectFunctionsEmulator(functions, "localhost", 5001);
    const getAllCandidates = httpsCallable(functions, "createNewCompany");
    getAllCandidates(payload).then((result) => {
        const companyId = result.data;
        commit("SET_COMPANY_ID", companyId);

    });
  },

};
  
export default {
  state,
  getters,
  actions,
  mutations,
};
