import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/store";
import { waitForAuth } from "@/utils/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "NotFound",
    component: function () {
      return import(
        /* webpackChunkName: "not-found" */ "../views/Auth/NotFound.vue"
      );
    },
  },
  {
    path: "/",
    name: "Login",
    component: function () {
      return import(/* webpackChunkName: "login" */ "../views/Auth/Login.vue");
    },
  },
  {
    path: "/register",
    name: "Register",
    // This should redirect to a view insted of a componente but we do not have this route in the prototype
    component: function () {
      return import(
        /* webpackChunkName: "register" */ "../components/Auth/Register.vue"
      );
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: function () {
      return import(
        /* webpackChunkName: "dashboard" */ "../views/Dashboard/Dashboard.vue"
      );
    },
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if(store.getters.userType == "RECLUTACHAT") {
          next("/CompaniesTable");
      } else {
          next();
      }
    }
  },
  {
    path: "/appointments",
    name: "Appointments",
    component: function () {
      return import(
        /* webpackChunkName: "appointments" */ "../views/Appointments/Appointments.vue"
      );
    },
    // meta: {requiresAuth: true},
  },
  {
    path: "/vacancies",
    name: "Vacancies",
    component: function () {
      return import(
        /* webpackChunkName: "vacancies" */ "../views/Vacancies/Vacancies.vue"
      );
    },
    // meta: {requiresAuth: true},
  },
  {
    path: "/tutorial",
    name: "Tutorial",
    component: function () {
      return import(
        /* webpackChunkName: "tutorial" */ "../views/Tutorials/TutorialView.vue"
      );
    },
    // meta: {requiresAuth: true},
  },
  {
    path: "/new-vacancy",
    name: "NewVacancy",
    component: function () {
      return import(
        /* webpackChunkName: "new-vacancy" */ "../views/Vacancies/NewVacancy"
      );
    },
    // meta: {requiresAuth: true},
  },
  {
    path: "/vacancies/:id",
    name: "DetailVacancy",
    component: function () {
      return import(
        /* webpackChunkName: "detail-vacancy" */ "../views/Vacancies/DetailVacancy"
      );
    },
    // meta: {requiresAuth: true},
  },
  {
    path: "/vacancies/detail-candidate/:id",
    name: "DetailCandidate",
    component: function () {
      return import(
        /* webpackChunkName: "detail-candidate" */ "../views/Candidates/DetailCandidate.vue"
      );
    },
    // meta: {requiresAuth: true},
  },
  {
    path: "/users/detail-user/:id",
    name: "DetailUser",
    component: function () {
      return import(
        /* webpackChunkName: "detail-user" */ "../views/Users/DetailUser.vue"
      );
    },
    // meta: {requiresAuth: true},
  },

  {
    path: "/users",
    name: "Users",
    component: function () {
      return import(/* webpackChunkName: "users" */ "../views/Users/Users");
    },
    beforeEnter: (to, from, next) => {
      if(store.getters.userType == "RECRUITER") {
          next("*");
      } else {
          next();
      }
  }
    // meta: {requiresAuth: true},
  },
  {
    path: "/new-user",
    name: "NewUser",
    component: function () {
      return import(
        /* webpackChunkName: "new-user" */ "../views/Users/NewUser"
      );
    },
    // meta: {requiresAuth: true},
  },
  {
    path: "/statistics",
    name: "Statistics",
    component: function () {
      return import(
        /* webpackChunkName: "statistics" */ "../views/Statistics/Statistics"
      );
    },
    beforeEnter: (to, from, next) => {
      if(store.getters.userType == "RECRUITER") {
          next("*");
      } else {
          next();
      }
    }
    // meta: {requiresAuth: true},
  },
  {
    path: "/my-business",
    name: "MyBusiness",
    component: function () {
      return import(
        /* webpackChunkName: "my-business" */ "../views/MyBusiness/MyBusiness"
      );
    },
    beforeEnter: (to, from, next) => {
      if(store.getters.userType == "RECRUITER") {
          next("*");
      } else {
          next();
      }
    // meta: {requiresAuth: true},
    }
  },
  {
    path: "/settings",
    name: "Settings",
    component: function () {
      return import(
        /* webpackChunkName: "settings" */ "../views/Settings/Settings"
      );
    },
    beforeEnter: (to, from, next) => {
      if(store.getters.userType == "RECRUITER") {
          next("*");
      } else {
          next();
      }
    }
    // meta: {requiresAuth: true},
  },
  // {
  //   path: "/CreateCompany",
  //   name: "CreateCompany",
  //   component: function () {
  //     return import(
  //       /* webpackChunkName: "CreateCompany" */ "../views/Company/CreateCompany"
  //     );
  //   }
  //   // meta: {requiresAuth: true},
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// New structure in beforeEach without using the waitForAuth utility
// I discard it in order to avoid an issue redirecting from the login
// This helps the way we're authenticating our users and how this allow them to get into our app
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const authCouldBeInitialized = await waitForAuth();
    if (authCouldBeInitialized && store.getters.isAuthenticated) {
      next();
    } else {
      next("/");
    }
  } else if (to.name === "Login") {
    const authCouldBeInitialized = await waitForAuth();
    if (authCouldBeInitialized && store.getters.isAuthenticated) {
      next("/dashboard");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
