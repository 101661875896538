import Vuex from "vuex";
import Vue from "vue";
import { getFunctions, httpsCallable } from "firebase/functions";

import ENUM from "@/store/enums";

Vue.use(Vuex);

const state = {
  status: ENUM.INIT,
  candidates: [],
  selectedVacancy: "",
  candidateSelected: [],
  createNewCandidate: [],
  candidateAttempt: "",
  ageCandidate: "",
  candidateCreated: false,
  editedCandidate: false,
  statusChange: false,
  selectedCandidate: []
};

const getters = {
  getCandidateAttempt: (state) => state.candidateAttempt,
  getAllCandidates(state) {
    if (state.candidates != "No candidates found!") {
      const candidatesToPrint = [];
      state.candidates.forEach(function (candidate) {
        if (candidate.candidate_is_active == true) {
          const candidateToPrint = {
            age: candidate.candidate_age,
            availability: candidate.candidate_availability,
            city: candidate.candidate_city,
            companyBsmg: candidate.candidate_company_bsmg_id,
            date: candidate.candidate_created_at,
            education: candidate.candidate_education,
            email: candidate.candidate_email,
            experience: candidate.andidate_experience,
            name: candidate.candidate_fullname,
            id: candidate.candidate_id,
            license: candidate.candidate_lincense_type,
            messengerId: candidate.candidate_messenger_id,
            phoneNumber: candidate.candidate_phone_number,
            recruitmentSource: candidate.candidate_recruitment_source,
            status: candidate.candidate_status,
            vacancyName: candidate.candidate_vacancy_name,
            zoneName: candidate.candidate_zone_name,
            VacancyCreatedBy: candidate.candidate_vacancy_created_by,
          };
          candidatesToPrint.push(candidateToPrint);
        }
      });
      return candidatesToPrint;
    } else {
      const candidatesToPrint = [];
      return candidatesToPrint;
    }
  },
  getCandidateData: (state) => state.candidateSelected,
};

const mutations = {
  SET_CANDIDATES(state, candidates) {
    state.candidates = candidates;
    state.status = ENUM.LOADED;
  },
  SELECT_VACANCY(state, vacancyId) {
    state.selectedVacancy = vacancyId;
    state.status = ENUM.LOADED;
  },
  SET_CANDIDATE(state, candidate) {
    state.candidateSelected = candidate;
    console.log(candidate, "candidate")
    state.status = ENUM.LOADED;
  },
  SET_NEW_CANDIDATE(state, candidateCreated) {
    state.createNewCandidate = candidateCreated;
    state.status = ENUM.LOADED;
  },
  SET_RESPONSE_FOR_CANDIDATE(state, candidateResponse) {
    state.candidateAttempt = candidateResponse;
    state.status = ENUM.LOADED;
  },
  CANDIDATES_LOADING(state) {
    state.status = ENUM.LOADING;
  },
  AGE_CANDIDATE(state, ageCandidate) {
    state.ageCandidate = ageCandidate;
  },
  CREATE_CANDIDATE(state, candidateCreatedD) {
    state.candidateCreated = candidateCreatedD;
  },
  EDITED_CANDIDATE(state, editedCandidateE) {
    state.editedCandidate = editedCandidateE;
  },
  SELECTED_CANDIDATE(state, selectedCandidate){
    state.selectedCandidate = selectedCandidate
  },
  STATUS_CHANGED(state, statusChangeD){
    state.statusChange = statusChangeD
  }
};

const actions = {
  async requestsAllCandidates({ commit }, candidateVacancyId) {
    commit("CANDIDATES_LOADING");
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    //connectFunctionsEmulator(functions, "localhost", 5001);

    const getAllCandidates = httpsCallable(functions, "readAllCandidates");
    const userCompanyId = this.state.auth.user.user_company;
    const vacancyZoneId = this.state.vacancies.selectedZone;
    getAllCandidates({
      candidate_company_id: userCompanyId,
      candidate_zone_id: vacancyZoneId,
      candidate_vacancy_id: candidateVacancyId,
    }).then((result) => {
      const candidates = result.data;
      commit("SET_CANDIDATES", candidates);
    });
  },

  requestsAllCandidatesWP({ commit }, payload) {
    return new Promise((resolve) => {
      commit("CANDIDATES_LOADING");
      const functions = getFunctions();
      // This connectFunctionsEmulator has to be commented in production mode,
      // we have to call it everywhere we have functions to call
      // connectFunctionsEmulator(functions, "localhost", 5001);

      const getAllCandidates = httpsCallable(functions, "readAllCandidates");
      const userCompanyId = this.state.auth.user.user_company;
      getAllCandidates({
        candidate_company_id: userCompanyId,
        candidate_zone_id: payload.zon,
        candidate_vacancy_id: payload.vac,
      }).then((result) => {
        const candidates = result.data;
        resolve(candidates);
        commit("SET_CANDIDATES", candidates);
      });
    });
  },

  async requestCandidateById({ commit }, candidateId) {
    commit("CANDIDATES_LOADING");
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    //connectFunctionsEmulator(functions, "localhost", 5001);

    const getAllCandidates = httpsCallable(functions, "readCandidateById");
    const userCompanyId = this.state.auth.user.user_company;
    const vacancyZoneId = this.state.vacancies.selectedZone;
    const candidateVacancyId = this.state.candidates.selectedVacancy;
    getAllCandidates({
      candidate_company_id: userCompanyId,
      candidate_zone_id: vacancyZoneId,
      candidate_vacancy_id: candidateVacancyId,
      candidate_id: candidateId,
    }).then((result) => {
      const candidates = result.data;
      commit("SET_CANDIDATE", candidates);
    });
  },

  async updateCandidateById({ commit }, payload) {
    commit("CANDIDATES_LOADING");
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    // connectFunctionsEmulator(functions, "localhost", 5001);

    const getAllCandidates = httpsCallable(functions, "updateCandidateById");
    payload["candidate_company_id"] = this.state.auth.user.user_company;
    payload["candidate_zone_id"] = this.state.vacancies.selectedZone;
    payload["candidate_vacancy_id"] = this.state.candidates.selectedVacancy;
    return getAllCandidates(payload).then((result) => {
      const candidates = result.data;
      // console.log(candidates, "result");
      commit("SET_RESPONSE_FOR_CANDIDATE", candidates);
      commit("EDITED_CANDIDATE", true);
    });
  },

  async deleteCandidatesById({ commit }, candidatesToDelete) {
    commit("CANDIDATES_LOADING");
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    // connectFunctionsEmulator(functions, "localhost", 5001);

    const getAllCandidates = httpsCallable(
      functions,
      "deactivateGroupCandidates"
    );
    const userCompanyId = this.state.auth.user.user_company;
    const vacancyZoneId = this.state.vacancies.selectedZone;
    const candidateVacancyId = this.state.candidates.selectedVacancy;
    // payload["candidate_company_id"] = this.state.auth.user.user_company;
    // payload["candidate_zone_id"] = this.state.vacancies.selectedZone;
    // payload["candidate_vacancy_id"] = this.state.candidates.selectedVacancy;
    getAllCandidates({
      company_id: userCompanyId,
      company_zone_id: vacancyZoneId,
      vacancy_id: candidateVacancyId,
      candidates_ids: candidatesToDelete,
    }).then((result) => {
      const candidates = result.data;
      commit("SET_RESPONSE_FOR_CANDIDATE", candidates);
    });
  },

  async createCandidate({ commit }, payload) {
    commit("CANDIDATES_LOADING");
    const functions = getFunctions();
    // This connectFunctionsEmulator has to be commented in production mode,
    // we have to call it everywhere we have functions to call
    // connectFunctionsEmulator(functions, "localhost", 5001);

    const generateCandidate = httpsCallable(functions, "createNewCandidate");
    //const userCompanyId = this.state.auth.user.user_company;
    //const vacancyZoneId = this.state.vacancies.selectedZone;
    //const candidateVacancyId = this.state.candidates.selectedVacancy;
    payload["candidate_company_id"] = this.state.auth.user.user_company;
    payload["candidate_zone_id"] = this.state.vacancies.selectedZone;
    payload["candidate_vacancy_id"] = this.state.candidates.selectedVacancy;
    generateCandidate(payload).then((result) => {
      const candidate = result.data;
      commit("SET_RESPONSE_FOR_CANDIDATE", candidate);
      commit("CREATE_CANDIDATE", true);
    });
  },
  async updateCandidateStatusById({commit}, payload){
    commit("CANDIDATES_LOADING");
    const functions = getFunctions();
    const getStatusUpdate = httpsCallable(
      functions,
      "updateCandidateStatusById"
    );
    await getStatusUpdate(payload).then((result) => {   
      const resultData = result.data;
      // console.log(result)
      this.dispatch("requestCandidateById", payload.candidate_id)
      commit("SET_RESPONSE_FOR_CANDIDATE", resultData);
      commit("STATUS_CHANGED", true)
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
