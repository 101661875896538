<template>
  <v-navigation-drawer
    permanent
    expand-on-hover
    color="#6E80F8"
    class="rounded-0"
    height="100vh"
    fixed
  >
    <v-list-item link>
      <v-icon small left color="transparent">mdi-menu</v-icon>
    </v-list-item>
    <v-divider></v-divider>

    <v-list nav dense>
      <v-list-item link to="/dashboard" v-if="userTypes != 'RECLUTACHAT'">
        <v-btn icon>
          <v-icon small color="#FFFFFF">mdi-home-outline</v-icon>
        </v-btn>
        <v-list-item-title class="item-side ml-4 font-weight-light text-caption"
          >Dashboard</v-list-item-title
        >
      </v-list-item>
      <!-- <v-list-item link to="/appointments" v-if="userTypes != 'RECLUTACHAT'">
        <v-btn icon>
          <v-icon small color="#FFFFFF">mdi-calendar-check</v-icon>
        </v-btn>
        <v-list-item-title class="item-side ml-4 font-weight-light text-caption"
          >Citas</v-list-item-title
        >
      </v-list-item> -->
      <v-list-item link to="/vacancies" v-if="userTypes != 'RECLUTACHAT'">
        <v-btn icon>
          <v-icon small color="#FFFFFF">mdi-bullhorn-outline</v-icon>
        </v-btn>
        <v-list-item-title class="item-side ml-4 font-weight-light text-caption"
          >Vacantes</v-list-item-title
        >
      </v-list-item>
      <v-list-item v-if="userTypes == 'ADMIN'" color="#FFFFFF" link to="/users">
        <v-btn icon>
          <v-icon small color="#FFFFFF">mdi-account-outline</v-icon>
        </v-btn>
        <v-list-item-title class="item-side ml-4 font-weight-light text-caption"
          >Usuarios</v-list-item-title
        >
      </v-list-item>

      <v-list-item v-if="userTypes == 'ADMIN'" link to="/statistics">
        <v-btn icon>
          <v-icon small color="#FFFFFF">mdi-chart-line</v-icon>
        </v-btn>
        <v-list-item-title class="item-side ml-4 font-weight-light text-caption"
          >Estadísticas</v-list-item-title
        >
      </v-list-item>
      <v-list-item v-if="userTypes == 'ADMIN'" link to="/my-business">
        <v-btn icon>
          <v-icon small color="#FFFFFF">mdi-briefcase-outline</v-icon>
        </v-btn>
        <v-list-item-title class="item-side ml-4 font-weight-light text-caption"
          >Mi empresa</v-list-item-title
        >
      </v-list-item>
      <v-list-item v-if="userTypes == 'ADMIN'" link to="/settings">
        <v-btn icon>
          <v-icon small color="#FFFFFF">mdi-cog-outline</v-icon>
        </v-btn>
        <v-list-item-title class="item-side ml-4 font-weight-light text-caption"
          >Settings</v-list-item-title
        >
      </v-list-item>
      <v-list-item v-if="userTypes == 'RECLUTACHAT'" link to="/CompaniesTable">
        <v-btn icon>
          <v-icon small color="#FFFFFF">mdi-home-plus-outline</v-icon>
        </v-btn>
        <v-list-item-title class="item-side ml-4 font-weight-light text-caption"
          >New Company</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  data: () => ({}),

  computed: {
    ...mapGetters(["userType"]),
    ...mapState({
      userTypes: (state) => state.auth.user.user_type,
    }),
  },
};
</script>

<style scoped>
.item-side {
  color: #fff;
}
</style>
