import Vuex from "vuex";
import Vue from "vue";
import {
  getFunctions,
  //
  httpsCallable,
} from "firebase/functions";

import ENUM from "@/store/enums";

Vue.use(Vuex);

const state = {
  status: ENUM.INIT,
  users: [],
  //selectedVacancy: '',
  userSelected: [],
  editedUser: false,
  deletedUser: false,
};

const getters = {
  getAllUsers(state) {
    const usersToPrint = [];
    state.users.forEach(function (user) {
      if (user.user_is_active == true) {
        const userToPrint = {
          name: user.user_first_name.concat(" ", user.user_last_name),
          phoneNumber: user.user_phone_number,
          type: user.user_type,
          area: user.user_area,
          email: user.user_contact_email,
          loginEmail: user.user_login_email,
          company: user.user_company,
          uid: user.user_uid,
        };
        usersToPrint.push(userToPrint);
      }
    });
    return usersToPrint;
  },
  getUserData: (state) => state.userSelected,
};

const mutations = {
  SET_USERS(state, users) {
    state.users = users;
    state.status = ENUM.LOADED;
  },
  SELECT_VACANCY(state, vacancyId) {
    state.selectedVacancy = vacancyId;
  },
  SET_USER_INFO(state, userInfo) {
    state.userSelected = userInfo;
    state.status = ENUM.LOADED;
  },
  USERS_LOADING(state) {
    state.status = ENUM.LOADING;
  },
  USERS_LOADED(state) {
    state.status = ENUM.LOADED;
  },
  EDITED_USER_ALERT(state, editedUserR){
    state.editedUser = editedUserR
  },
  DELETED_USER(state, deletedUserR){
    state.deletedUser = deletedUserR
  }
};

const actions = {
    async requestsAllUsers({commit}, userZoneId) {
        const functions = getFunctions();
        // This connectFunctionsEmulator has to be commented in production mode,
        // we have to call it everywhere we have functions to call
        //connectFunctionsEmulator(functions, "localhost", 5001);
        const getAllUsers = httpsCallable(functions, "readUserByCompanyZone");
        const userCompanyId = this.state.auth.user.user_company;
        getAllUsers({
            company_id: userCompanyId,
            zone_id: userZoneId,
        }).then((result) => {
            const users = result.data;
            commit("SET_USERS", users);
            commit("SELECT_ZONE", userZoneId);
        });
    },
    async requestsUser({commit}, userId) {
        const functions = getFunctions();
        // This connectFunctionsEmulator has to be commented in production mode,
        // we have to call it everywhere we have functions to call
        //connectFunctionsEmulator(functions, "localhost", 5001);

        const getAllUsers = httpsCallable(functions, "readUserById");
        getAllUsers({
            user_id: userId,
        }).then((result) => {
            const user = result.data[0];
            commit("SET_USER_INFO", user);
        });
    },

    async updateUserById( {commit} , payload) {
        commit("USERS_LOADING");
        const functions = getFunctions();
        // This connectFunctionsEmulator has to be commented in production mode,
        // we have to call it everywhere we have functions to call
        //connectFunctionsEmulator(functions, "localhost", 5001);

        const getAllCandidates = httpsCallable(functions, "updateUserById");
        getAllCandidates(payload).then((result) => {
            const candidates = result.data;
            // console.log(candidates, "editar candidato")
            commit("SET_USERS", candidates);
            commit("EDITED_USER_ALERT", true)
        });
    },

    async deleteUsersById( {commit} , usersToDelete) {
        const functions = getFunctions();
        // This connectFunctionsEmulator has to be commented in production mode,
        // we have to call it everywhere we have functions to call
        //connectFunctionsEmulator(functions, "localhost", 5001);
        const userCompanyId = this.state.auth.user.user_company;
        const getAllCandidates = httpsCallable(functions, "deactivateUsersById");
        getAllCandidates({"users_Ids": usersToDelete, "user_company":userCompanyId }).then((result) => {
            const candidates = result.data;
            commit("SET_USERS", candidates);
            commit("DELETED_USER", true)
        });
    },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
